<script setup lang="ts">
  import { JobSearch } from '@borg/types';
  import { MpCard, MpIcon, MpText } from '@borg/ui';

  const props = defineProps<{
    item: JobSearch;
    large?: boolean;
    hideIcon?: boolean;
    removable?: boolean;
  }>();

  const emits = defineEmits<{
    (eventName: 'clicked', item: JobSearch): void;
    (eventName: 'remove', item: JobSearch): void;
  }>();

  const jobsSearchStore = useJobsSearchStore();

  function onClickElement(searchItem: JobSearch) {
    if (!props.removable) {
      onSearchClick(searchItem);
    }
  }

  function onClickElementPart(searchItem: JobSearch) {
    if (props.removable) {
      onSearchClick(searchItem);
    }
  }

  function onSearchClick(searchItem: JobSearch) {
    if (searchItem.params) {
      jobsSearchStore.replaceStaticFilter(searchItem.params.static);
      jobsSearchStore.replaceDynamicFilter(searchItem.params.dynamic);
    }

    emits('clicked', searchItem);

    navigate({ name: 'search', query: getSearchQueryObject(searchItem.params) });
  }
</script>

<template>
  <MpCard
    class="item"
    p="sm"
    border
    corners="round-sm"
    @click="onClickElement(item)"
  >
    <MpIcon
      v-if="!hideIcon"
      icon="history"
      class="item__icon-history"
      size="sm"
      @click="onClickElementPart(item)"
    />
    <div
      class="item__content content"
      @click="onClickElementPart(item)"
    >
      <MpText
        v-if="item.newJobsCount"
        :class="{
          'content__new-jobs-count': true,
          'content__new-jobs-count--large': large,
        }"
        type="body3"
        no-wrap
      >
        {{ $t('JOB_SEARCH_ITEM.COUNT_LABEL', { count: item.newJobsCount }) }}
      </MpText>
      <MpText
        v-if="item.title"
        class="content__positions"
        :type="large ? 'h5' : 'body2'"
        no-margin
        :semibold="!large"
        :bold="large"
      >
        {{ item.title }}
      </MpText>
      <MpText
        v-if="item.subtitle && !large"
        class="content__locations"
        type="body2"
        no-margin
      >
        {{ item.subtitle }}
      </MpText>
      <MpText
        v-if="item.description && !large"
        class="content__filters"
        type="body3"
        no-margin
      >
        {{ item.description }}
      </MpText>
    </div>
    <MpIcon
      v-if="removable"
      icon="close"
      class="item__icon-remove"
      @click="emits('remove', item)"
    />
  </MpCard>
</template>

<style scoped lang="scss">
  .item {
    display: flex;
    gap: var(--mp-space-40);
    background-color: var(--mp-color-grey-100);

    @include dark-mode {
      background-color: var(--mp-color-surface-10);
    }

    &__icon-history {
      margin: auto;
      cursor: pointer;
    }

    &__content {
      flex: 2;
      justify-self: flex-start;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin: auto;
    }

    &__icon-remove {
      cursor: pointer;
      margin: auto;
    }
  }

  .content {
    overflow: auto;

    & > :last-child {
      margin-bottom: 0;
    }

    &__new-jobs-count {
      color: var(--mp-color-primary-dark);
      width: fit-content;
      padding: 0 var(--mp-space-20);
      margin-bottom: var(--mp-space-20);
      border: 1px solid var(--mp-color-primary-dark);
      border-radius: var(--mp-space-20);

      @include dark-mode {
        color: var(--mp-color-secondary-light);
        border-color: var(--mp-color-secondary-light);
      }

      &--large {
        margin-bottom: var(--mp-space-30);
      }
    }
  }
</style>
